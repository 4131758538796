import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter,
} from "react-router-dom";

//Custom Components

import Home from "../pages/home";
import About from "../pages/about";
import Portfolio from "../pages/Portfolio";
import TeamDetails from "../pages/team/team-details";
import Blog from "../pages/blog";
import BlogDetails from "../pages/blog/blog-details";
import Services from "../pages/service";
import WebDevelopment from "../pages/web-development";
import Solution from "../pages/solution";
import AngularJs from "../pages/angularJs-development";
import Codeigniter from "../pages/codeigniter-development";
import VueJs from "../pages/vueJs-development";
import JavaScripts from "../pages/javaScripts-development";

import WordpressDevelopment from "../pages/cms-development";

import CaseStudtyStyleOne from "../pages/case-study/style-1";
import phpDevelopment from "../pages/php-development";
import ReactJS from "../pages/reactJs-development";
import Laravel from "../pages/laravel-development";
import MobileAppDevelopment from "../pages/mobile-app-development";
import AndroidAppDevelopment from "../pages/android-app-development";
import IosAppDevelopment from "../pages/ios-app-development";
import webDesign from "../pages/ux-ui-design";

import NodeJS from "../pages/nodeJs-development";

import Front_End from "../pages/front-end";

import eCommerce from "../pages/eCommerce-development";
import Careers from "../pages/careers";
import FintechAppMain from "../pages/fintech-app-solution";
import FitnessAppMain from "../pages/fitness-wellness-app-solutions";
import EcommerceSolutionsMain from "../pages/ecommerce-solutions";
import BusinessAppSolution from "../pages/business-app-solution";
import TravelAppMain from "../pages/travel-app-solutions";
import RealEstateAppMain from "../pages/real-estate-app-solution";
import HealthcareAppMain from "../pages/healthcare-app-solution";
import SocialMediaAppMain from "../pages/social-media-app";
import HireDevelopersMain from "../pages/hire-dedicated-developers";

import HirePHPDevelopersMain from "../pages/hire-php-developers";
import HireQaDevelopersMain from "../pages/hire-qa-developers";

import HireReactNativeDevelopersMain from "../pages/hire-react-native-developers";

import HireMeanStackDevelopersMain from "../pages/hire-mean-stack-developers";

import HireFullStackDevelopersMain from "../pages/hire-full-stack-developers";

import HireFlutterDevelopersMain from "../pages/hire-flutter-developers";

import HirePythonDevelopersMain from "../pages/hire-python-developers";

import HireLaravelDevelopersPage from "../pages/hire-laravel-developers";
import HireNodeJsDevelopersPage from "../pages/hire-nodejs-developers";
import HireReactJsDevelopersPage from "../pages/hire-reactjs-developers";
import HireAngularJsDevelopersPage from "../pages/hire-angularjs-developer";
import HireiOSAppDevelopers from "../pages/hire-ios-app-developer";
import HireVueJsDevelopersPage from "../pages/hire-vuejs-developer";
import HireAndroidAppDevelopers from "../pages/hire-android-app-developer";

import HireWordpressDevelopers from "../pages/hire-wordpress-developer";

import HireWebDesignerDevelopers from "../pages/hire-ux-ui-developers";
import HireCrossPlatformAppDevelopers from "../pages/hire-cross-platform-app-developers";
import HireWebDevelopers from "../pages/hire-web-developers";
import Contact from "../pages/contact";
import Faq from "../pages/faq";
import Error from "../pages/404";
import LoadTop from "../components/Common/ScrollTop/LoadTop";
import BlogDetail from "../pages/blog/BlogDetail"

const App = () => {
  return (
    <div className="App">
      <Router>
        <LoadTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/service" exact component={Services} />
          <Route path="/solution" component={Solution} />
          <Route path="/php-development" exact component={phpDevelopment} />
          <Route path="/angularJs-development" exact component={AngularJs} />
          <Route
            path="/mobile-app-development"
            exact
            component={MobileAppDevelopment}
          />
          <Route
            path="/android-app-development"
            exact
            component={AndroidAppDevelopment}
          />
          <Route
            path="/ios-app-development"
            exact
            component={IosAppDevelopment}
          />
          <Route
            path="/ecommerce-solutions"
            exact
            component={EcommerceSolutionsMain}
          />
          <Route path="/reactJs-development" exact component={ReactJS} />
          <Route
            path="/codeigniter-development"
            exact
            component={Codeigniter}
          />
          <Route path="/ux-ui-design" exact component={webDesign} />
          <Route
            path="/javaScripts-development"
            exact
            component={JavaScripts}
          />
          <Route path="/laravel-development" exact component={Laravel} />
          <Route path="/vueJs-development" exact component={VueJs} />

          <Route path="/nodeJs-development" exact component={NodeJS} />

          <Route path="/front-end" exact component={Front_End} />

          <Route path="/web-development" exact component={WebDevelopment} />

          <Route
            path="/cms-development"
            exact
            component={WordpressDevelopment}
          />

          <Route path="/eCommerce-development" exact component={eCommerce} />
          <Route
            path="/fintech-app-solution"
            exact
            component={FintechAppMain}
          />
          <Route
            path="/business-app-solution"
            exact
            component={BusinessAppSolution}
          />
          <Route
            path="/fitness-wellness-app-solutions"
            exact
            component={FitnessAppMain}
          />
          <Route path="/travel-app-solutions" exact component={TravelAppMain} />
          <Route
            path="/real-estate-app-solution"
            exact
            component={RealEstateAppMain}
          />
          <Route
            path="/healthcare-app-solution"
            exact
            component={HealthcareAppMain}
          />
          <Route
            path="/social-media-app"
            exact
            component={SocialMediaAppMain}
          />
          <Route
            path="/hire-dedicated-developers"
            exact
            component={HireDevelopersMain}
          />

          <Route
            path="/hire-php-developers"
            exact
            component={HirePHPDevelopersMain}
          />
          <Route
            path="/hire-qa-developers"
            exact
            component={HireQaDevelopersMain}
          />

          <Route
            path="/hire-react-native-developers"
            exact
            component={HireReactNativeDevelopersMain}
          />

          <Route
            path="/hire-mean-stack-developers"
            exact
            component={HireMeanStackDevelopersMain}
          />

          <Route
            path="/hire-full-stack-developers"
            exact
            component={HireFullStackDevelopersMain}
          />

          <Route
            path="/hire-flutter-developers"
            exact
            component={HireFlutterDevelopersMain}
          />

          <Route
            path="/hire-python-developers"
            exact
            component={HirePythonDevelopersMain}
          />

          <Route
            path="/hire-laravel-developers"
            exact
            component={HireLaravelDevelopersPage}
          />
          <Route
            path="/hire-nodejs-developers"
            exact
            component={HireNodeJsDevelopersPage}
          />
          <Route
            path="/hire-reactjs-developers"
            exact
            component={HireReactJsDevelopersPage}
          />
          <Route
            path="/hire-vuejs-developer"
            exact
            component={HireVueJsDevelopersPage}
          />
          <Route
            path="/hire-android-app-developer"
            exact
            component={HireAndroidAppDevelopers}
          />
          <Route
            path="/hire-ios-app-developer"
            exact
            component={HireiOSAppDevelopers}
          />
          <Route
            path="/hire-angular-developer"
            exact
            component={HireAngularJsDevelopersPage}
          />
          <Route
            path="/hire-wordpress-developer"
            exact
            component={HireWordpressDevelopers}
          />
          <Route
            path="/hire-ux-ui-developers"
            exact
            component={HireWebDesignerDevelopers}
          />
          <Route
            path="/hire-web-developers"
            exact
            component={HireWebDevelopers}
          />
          <Route
            path="/hire-cross-platform-app-developers"
            exact
            component={HireCrossPlatformAppDevelopers}
          />
          <Route path="/Portfolio" exact component={Portfolio} />
          <Route path="/team/team-details" component={TeamDetails} />
          <Route path="/blog" exact component={Blog} />
          
          <Route path="/blog/blog-details/:blogId" component={BlogDetail} />
          <Route path="/case-study/style-1" component={CaseStudtyStyleOne} />
          <Route path="/careers" component={Careers} />
          <Route path="/faq" component={Faq} />
          <Route path="/contact" component={Contact} />
          <Route component={Error} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
