import React from "react";

import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import ScrollToTop from "../../components/Common/ScrollTop";
import Blogs from "../../components/Common/Breadcumb/blogs";
import BlogMain from "../../components/Blog";

// Breadcrumbs Background Image
import bannerbg from "../../assets/img/banner/banner-6.jpg";

const Blog = () => {
  return (
    <React.Fragment>
      <HeaderStyleThree parentMenu="blog" activeMenu="/blog" />
      <div>
        <Blogs
          pageTitle="Blog"
          titleClass="page-title"
          pageName="Blog"
          breadcrumbsImg={bannerbg}
          animatedFont="lined-Font dance2"
          animatedFontsize="Blog"
        />

       
        <BlogMain />
        {/* Blog Main End */}

        {/* scrolltop-start */}
        <ScrollToTop />
        {/* scrolltop-end */}
      </div>
      <Footer />
      <SearchModal />
    </React.Fragment>
  );
};

export default Blog;
