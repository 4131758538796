import React from 'react';
import { Link } from 'react-router-dom';
import bannerbg from '../../../assets/img/banner/about_us.png';

const SiteBreadcrumb = (props) => {
	const { pageTitle, titleClass, parentCategory, pageCategory, pageName, breadcrumbsImg, animatedFont, animatedFontsize } = props;

	const breadcrumbsImgStyle = {
		backgroundImage: `url(${breadcrumbsImg})`
	}
	return (
		<div className="rs-breadcrumbs rs-rain-animate blog_list" style={breadcrumbsImgStyle}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12">
						<div className="breadcrumbs-inner pt-300 pb-250 md-pt-200 md-pb-150 sm-pt-180 sm-pb-50">
							<h1 className={titleClass ? titleClass : 'page-title'}>{pageTitle}</h1>
							<ul>
								<li>
									<Link to="/" className="active">{parentCategory ? parentCategory : 'Home'}</Link>
								</li>
								{pageCategory ? 
									<>
										
										<Link to="/blog" className="active">Blog</Link>
									</> : <li>{pageName ? pageName : 'Page Name'}</li>							
								}	
							</ul>
							{/* <h2 className={animatedFont ? animatedFont : 'lined-Font'}>{animatedFontsize ? animatedFontsize : 'page-title'}</h2> */}
						</div>
					</div>
					
				</div>
				<div className="line-inner style2">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                </div>
			</div>
		</div>
	);
}

export default SiteBreadcrumb;




